import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlusCircle, Folder, X, ChevronRight, MoreVertical, Loader2 } from 'lucide-react';
import { toast } from 'react-toastify';
import Login from './Login';

function Notes() {
  const [folders, setFolders] = useState([]);
  const [notes, setNotes] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchNotes = useCallback(async () => {
    try {
      const response = await fetch('/api/notes');
      const data = await response.json();
      setFolders(data.folders);
      setNotes(data.notes);
      setIsAuthenticated(data.is_authenticated);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to fetch notes. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleLogin = async (success) => {
    if (success) {
      setIsAuthenticated(true);
      setShowLoginForm(false);
      await fetchNotes();
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include',
      });
      const data = await response.json();
      if (data.success) {
        setIsAuthenticated(false);
        await fetchNotes();
        toast.success('Logged out successfully');
      } else {
        toast.error('Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred during logout.');
    }
  };

  const handleAddNote = () => {
    navigate('/new_note');
  };

  const createFolder = async (e) => {
    e.preventDefault();
    if (!newFolderName.trim()) {
      toast.error('Folder name cannot be empty');
      return;
    }
    try {
      const response = await fetch('/api/create_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ folder_name: newFolderName })
      });
      const data = await response.json();
      if (data.success) {
        setFolders([...folders, data.folder]);
        setNewFolderName('');
        setShowNewFolderInput(false);
        toast.success('Folder created successfully');
      } else {
        toast.error('Failed to create folder: ' + data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while creating the folder.');
    }
  };

  const deleteFolder = async (folderId) => {
    if (window.confirm('Are you sure you want to delete this folder? Notes will be moved to Uncategorized.')) {
      try {
        const response = await fetch('/api/delete_folder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ folder_id: folderId })
        });
        const data = await response.json();
        if (data.success) {
          setFolders(folders.filter(folder => folder.id !== folderId));
          setNotes(notes.map(note => 
            note.folder_id === folderId ? {...note, folder_id: null} : note
          ));
          toast.success('Folder deleted successfully');
        } else {
          toast.error('Failed to delete folder: ' + data.error);
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred while deleting the folder.');
      }
    }
  };

  const updateNoteFolder = async (noteId, folderId) => {
    try {
      const response = await fetch('/api/update_note_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ note_id: noteId, folder_id: folderId })
      });
      const data = await response.json();
      if (!data.success) {
        setNotes(prevNotes => prevNotes.map(note => 
          note.id === noteId ? {...note, folder_id: note.folder_id} : note
        ));
        toast.error('Failed to update note folder: ' + data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      setNotes(prevNotes => prevNotes.map(note => 
        note.id === noteId ? {...note, folder_id: note.folder_id} : note
      ));
      toast.error('An error occurred while updating the note folder.');
    }
  };

  const onDragEnd = (result) => {
    if (!isAuthenticated) return;

    const { destination, source, type, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'folder') {
      // Prevent folders from being dropped in invalid locations
      if (destination.droppableId !== 'folders') {
        return;
      }

      const newFolders = Array.from(folders);
      const [reorderedFolder] = newFolders.splice(source.index, 1);
      newFolders.splice(destination.index, 0, reorderedFolder);

      setFolders(newFolders);

      const folderOrder = newFolders.map(folder => folder.id);
      fetch('/api/update_folder_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ folder_order: folderOrder })
      })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          toast.error('Failed to update folder order: ' + data.error);
          fetchNotes();
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('An error occurred while updating folder order.');
        fetchNotes();
      });
    } else if (type === 'note') {
      // Validate note drop location
      if (!destination.droppableId || 
          (destination.droppableId !== 'uncategorized' && 
           !folders.some(f => f.id.toString() === destination.droppableId))) {
        return;
      }

      const newFolderId = destination.droppableId === 'uncategorized' ? null : parseInt(destination.droppableId);
      const noteId = parseInt(draggableId);

      setNotes(prevNotes => prevNotes.map(note => 
        note.id === noteId ? {...note, folder_id: newFolderId} : note
      ));

      updateNoteFolder(noteId, newFolderId);
    }
  };

  const renderNotes = (folderNotes, folderId) => {
    return (
      <Droppable 
        droppableId={folderId === null ? 'uncategorized' : folderId.toString()} 
        type="note"
        isCombineEnabled={false}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`p-2 space-y-1 min-h-[40px] ${
              snapshot.isDraggingOver ? 'bg-gray-700/30' : ''
            }`}
          >
            {folderNotes.map((note, index) => (
              <Draggable 
                key={note.id} 
                draggableId={note.id.toString()} 
                index={index}
                isDragDisabled={!isAuthenticated}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`
                      flex items-center gap-2 p-2 rounded
                      ${snapshot.isDragging ? 'bg-gray-600/80 shadow-lg' : 'hover:bg-gray-700/50'}
                      transition-colors duration-200
                    `}
                    style={{
                      ...provided.draggableProps.style,
                      transform: snapshot.isDragging 
                        ? `${provided.draggableProps.style.transform}`
                        : 'none'
                    }}
                  >
                    <ChevronRight size={16} className="text-gray-500" />
                    <Link 
                      to={`/notes/${note.id}`}
                      className="flex-grow text-sm text-gray-300 hover:text-gray-100"
                    >
                      {note.title}
                    </Link>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="flex items-center gap-3">
          <Loader2 className="w-6 h-6 animate-spin" />
          <span className="text-xl text-gray-400">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-gray-100">
      <div className="border-b border-gray-800 px-4 py-3">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-blue-100">Cybersecurity Notes</h1>
          <div className="flex gap-2">
            <Link 
              to="/" 
              className="px-4 py-1.5 bg-gray-800 hover:bg-gray-700 rounded text-sm transition-colors"
            >
              Back to Home
            </Link>
            {isAuthenticated ? (
              <>
                <button 
                  onClick={handleAddNote}
                  className="px-4 py-1.5 bg-green-600 hover:bg-green-500 rounded text-sm transition-colors"
                >
                  Add New Note
                </button>
                <button 
                  onClick={handleLogout}
                  className="px-4 py-1.5 bg-red-600 hover:bg-red-500 rounded text-sm transition-colors"
                >
                  Logout
                </button>
              </>
            ) : (
              <button 
                onClick={() => setShowLoginForm(true)}
                className="px-4 py-1.5 bg-blue-600 hover:bg-blue-500 rounded text-sm transition-colors"
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto p-4">
        {showLoginForm && !isAuthenticated && (
          <Login onLogin={handleLogin} />
        )}

        {isAuthenticated && !showNewFolderInput && (
          <button 
            onClick={() => setShowNewFolderInput(true)}
            className="mb-6 px-4 py-2 bg-blue-600 hover:bg-blue-500 rounded text-sm transition-colors flex items-center gap-2"
          >
            <PlusCircle size={16} />
            New Folder
          </button>
        )}

        {showNewFolderInput && (
          <form onSubmit={createFolder} className="mb-6 flex gap-2">
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="New folder name"
              className="px-3 py-2 bg-gray-800 rounded flex-grow"
              autoFocus
            />
            <button 
              type="submit" 
              className="px-4 py-2 bg-blue-600 hover:bg-blue-500 rounded text-sm transition-colors"
            >
              Create
            </button>
            <button 
              onClick={() => setShowNewFolderInput(false)}
              className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded text-sm transition-colors"
            >
              Cancel
            </button>
          </form>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable 
            droppableId="folders" 
            type="folder" 
            direction="horizontal"
            isCombineEnabled={false}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ${
                  snapshot.isDraggingOver ? 'bg-gray-800/10' : ''
                }`}
                style={{
                  display: 'grid',
                  minHeight: snapshot.isDraggingOver ? '200px' : 'auto'
                }}
              >
                {folders.map((folder, index) => (
                  <Draggable 
                    key={folder.id} 
                    draggableId={folder.id.toString()} 
                    index={index}
                    isDragDisabled={!isAuthenticated}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`
                          bg-gray-800/50 rounded-lg backdrop-blur-sm relative isolate
                          ${snapshot.isDragging ? 'shadow-lg ring-2 ring-blue-500 z-50' : ''}
                        `}
                        style={{
                          ...provided.draggableProps.style,
                          transform: snapshot.isDragging 
                            ? `${provided.draggableProps.style.transform}`
                            : 'none',
                          gridColumn: snapshot.isDragging ? 'auto' : undefined,
                        }}
                      >
                        <div className="p-4 flex items-center justify-between border-b border-gray-700">
                          <div className="flex items-center gap-2">
                            <Folder size={18} className="text-gray-400" />
                            <h3 className="font-medium">{folder.name}</h3>
                          </div>
                          <div className="flex items-center gap-2">
                            {isAuthenticated && (
                              <button 
                              onClick={() => deleteFolder(folder.id)}
                                className="text-gray-400 hover:text-red-400 transition-colors"
                              >
                                <X size={18} />
                              </button>
                            )}
                            <div {...provided.dragHandleProps}>
                              <MoreVertical size={18} className="text-gray-400" />
                            </div>
                          </div>
                        </div>
                        {renderNotes(
                          notes.filter(note => note.folder_id === folder.id),
                          folder.id
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                
                {/* Uncategorized Notes */}
                <div className="bg-gray-800/50 rounded-lg backdrop-blur-sm">
                  <div className="p-4 flex items-center gap-2 border-b border-gray-700">
                    <Folder size={18} className="text-gray-400" />
                    <h3 className="font-medium">Uncategorized Notes</h3>
                  </div>
                  {renderNotes(
                    notes.filter(note => note.folder_id === null),
                    'uncategorized'
                  )}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default Notes;