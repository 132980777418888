import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './Components/Header';
import Hero from './Components/Hero';
import About from './Components/About';
import Experience from './Components/Experience';
import Footer from './Components/Footer';
import Notes from './Components/Notes';
import Projects from './Components/Projects';
import NoteView from './Components/NoteView';
import Logout from './Components/Logout';
import EditNote from './Components/EditNote';
import AddNote from './Components/AddNote';
import Terminal from './Components/Terminal';
import Oneko from './Components/Oneko';

function App() {
  const [nekoEnabled, setNekoEnabled] = useState(false);
  const [nekoVariant, setNekoVariant] = useState(() => {
    return localStorage.getItem('oneko:variant') || 'classic';
  });
  
  const [kuroNeko, setKuroNeko] = useState(() => {
    return JSON.parse(localStorage.getItem('oneko:kuroneko') || 'false');
  });

  return (
    <Router>
      <AnimatePresence>
        <div className="App bg-black min-h-screen text-white">
          {nekoEnabled && <Oneko variant={nekoVariant} kuroNeko={kuroNeko} />}
          <Header 
            onVariantChange={setNekoVariant}
            onKuroNekoToggle={setKuroNeko}
            onNekoToggle={setNekoEnabled}
            nekoEnabled={nekoEnabled}
          />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <About />
                  <Experience />
                </>
              }
            />
            <Route path="/notes" element={<Notes />} />
            <Route path="/notes/:noteId" element={<NoteView />} />  
            <Route path="/projects" element={<Projects />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/editnote" element={<EditNote/>} />
            <Route path="/new_note" element={<AddNote/>} />
           # <Route path="/cli" element={<Terminal/>} />
          </Routes>
          <Footer />
        </div>
      </AnimatePresence>
    </Router>
  );
}

export default App;