import React, { useState, useEffect } from 'react';
import { FileText, ChevronRight, Loader2, Folder, ChevronDown } from 'lucide-react';
import Login from './Login';

const Projects = () => {
  const [files, setFiles] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);

  useEffect(() => {
    fetch('/api/pdfs')
      .then(response => {
        if (response.status === 401) {
          setIsAuthenticated(false);
          setShowLoginForm(true);
          throw new Error('Authentication required');
        }
        if (!response.ok) throw new Error('Failed to fetch PDFs');
        return response.json();
      })
      .then(data => {
        setFiles(data.files);
        setIsAuthenticated(true);
        setLoading(false);
      })
      .catch(error => {
        if (error.message !== 'Authentication required') {
          setError('Error loading PDFs: ' + error.message);
        }
        setLoading(false);
      });
  }, [isAuthenticated]);

  const handleLogin = async (success) => {
    if (success) {
      setIsAuthenticated(true);
      setShowLoginForm(false);
      setError(null);
    }
  };

  const handlePdfClick = async (path) => {
    try {
      const response = await fetch(`/api/view-pdf/${encodeURIComponent(path)}`);
      if (response.status === 401) {
        setIsAuthenticated(false);
        setShowLoginForm(true);
        throw new Error('Authentication required');
      }
      if (!response.ok) throw new Error('Failed to get PDF URL');
      const data = await response.json();
      setSelectedPdf(data.url);
    } catch (error) {
      if (error.message !== 'Authentication required') {
        setError('Error viewing PDF: ' + error.message);
      }
    }
  };

  const toggleFolder = (folderPath) => {
    setExpandedFolders(prev => {
      const newSet = new Set(prev);
      if (newSet.has(folderPath)) {
        newSet.delete(folderPath);
      } else {
        newSet.add(folderPath);
      }
      return newSet;
    });
  };

  const FileTreeItem = ({ item, depth = 0 }) => {
    const isFolder = item.type === 'folder';
    const isExpanded = expandedFolders.has(item.path);
    
    return (
      <div className="flex flex-col">
        <button
          onClick={() => isFolder ? toggleFolder(item.path) : handlePdfClick(item.path)}
          className={`flex items-center gap-3 p-3 rounded-lg transition-all duration-200 group w-full
            ${!isFolder && selectedPdf && item.path === selectedPdf.split('/').pop() 
              ? 'bg-blue-500/20 text-blue-400 border-blue-500/50' 
              : 'hover:bg-gray-700/50 text-gray-300 hover:text-blue-400'} 
            border border-transparent hover:border-gray-700`}
          style={{ paddingLeft: `${depth * 1.5 + 1}rem` }}
        >
          {isFolder ? (
            <>
              {isExpanded ? (
                <ChevronDown className="w-4 h-4" />
              ) : (
                <ChevronRight className="w-4 h-4" />
              )}
              <Folder className="w-5 h-5" />
            </>
          ) : (
            <>
              <FileText className="w-5 h-5" />
              <span className="w-4" /> {/* Spacing to align with folders */}
            </>
          )}
          <span className="flex-1 text-left text-sm truncate">
            {item.name.replace('.pdf', '')}
          </span>
          {!isFolder && (
            <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
          )}
        </button>
        
        {isFolder && isExpanded && (
          <div className="flex flex-col">
            {item.children.map((child, index) => (
              <FileTreeItem key={index} item={child} depth={depth + 1} />
            ))}
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="flex items-center gap-3 text-xl text-blue-400">
          <Loader2 className="w-6 h-6 animate-spin" />
          Loading projects...
        </div>
      </div>
    );
  }

  if (showLoginForm && !isAuthenticated) {
    return (
      <div className="container mx-auto p-4 min-h-screen">
        <div className="mb-8">
          <h1 className="text-4xl font-bold mb-2">Course Projects</h1>
          <p className="text-gray-400">Please log in to view projects</p>
        </div>
        <Login onLogin={handleLogin} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="bg-red-500/10 text-red-500 p-4 rounded-lg">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 min-h-screen">
      <div className="mb-8">
        <h1 className="text-4xl font-bold mb-2">Course Projects</h1>
        <p className="text-gray-400">View and explore my cybersecurity coursework and assignments</p>
      </div>
      
      {files.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[400px] bg-gray-800/50 rounded-lg">
          <FileText className="w-16 h-16 text-gray-600 mb-4" />
          <div className="text-gray-400">No files available</div>
        </div>
      ) : (
        <div className="grid md:grid-cols-12 gap-6">
          {/* File Tree Sidebar */}
          <div className="md:col-span-4 lg:col-span-3">
            <div className="bg-gray-800/50 p-6 rounded-lg backdrop-blur-sm border border-gray-700/50">
              <h2 className="text-xl font-semibold mb-4">Project Files</h2>
              <div className="space-y-2">
                {files.map((item, index) => (
                  <FileTreeItem key={index} item={item} />
                ))}
              </div>
            </div>
          </div>

          {/* PDF Viewer */}
          <div className="md:col-span-8 lg:col-span-9">
            <div className="bg-gray-800/50 rounded-lg backdrop-blur-sm border border-gray-700/50 h-full min-h-[600px]">
              {selectedPdf ? (
                <div className="w-full h-screen max-h-[800px]">
                  <iframe
                    src={selectedPdf}
                    className="w-full h-full rounded-lg"
                    title="PDF Viewer"
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center h-full text-gray-400">
                  <FileText className="w-16 h-16 mb-4 text-gray-600" />
                  <p>Select a project file to view</p>
                  <p className="text-sm mt-2 text-gray-500">Your work will appear here</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;