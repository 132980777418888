import { useEffect, useRef } from 'react';

const Oneko = ({ variant = "classic", kuroNeko: initialKuroNeko = false }) => {
  const kuroNekoRef = useRef(initialKuroNeko);

  useEffect(() => {
    const nekoEl = document.createElement("div");
    let nekoPosX = 32,
      nekoPosY = 32,
      mousePosX = 0,
      mousePosY = 0,
      frameCount = 0,
      idleTime = 0,
      idleAnimation = null,
      idleAnimationFrame = 0,
      isDragging = false,
      grabStop = true,
      nudge = false,
      velocityX = 0,
      velocityY = 0,
      lastMouseX = 0,
      lastMouseY = 0,
      isBeingThrown = false,
      forceSleep = false,
      lastFrameTime = Date.now(); // Add this to track frame timing

      const nekoSpeed = 4.75;
      const friction = 0.95;      
      const throwMultiplier = 1.2; 
      const bounceMultiplier = 0.8; 
      const minimumThrowVelocity = 1.5; 
      const angleThreshold = 20;
      const FRAME_INTERVAL = 16;  
      const ANIMATION_SPEED_DIVISOR = 10; 

    const spriteSets = {
      idle: [[-3, -3]],
      alert: [[-7, -3]],
      scratchSelf: [[-5, 0], [-6, 0], [-7, 0]],
      scratchWallN: [[0, 0], [0, -1]],
      scratchWallS: [[-7, -1], [-6, -2]],
      scratchWallE: [[-2, -2], [-2, -3]],
      scratchWallW: [[-4, 0], [-4, -1]],
      tired: [[-3, -2]],
      sleeping: [[-2, 0], [-2, -1]],
      N: [[-1, -2], [-1, -3]],
      NE: [[0, -2], [0, -3]],
      E: [[-3, 0], [-3, -1]],
      SE: [[-5, -1], [-5, -2]],
      S: [[-6, -3], [-7, -2]],
      SW: [[-5, -3], [-6, -1]],
      W: [[-4, -2], [-4, -3]],
      NW: [[-1, 0], [-1, -1]],
    };

    function setSprite(name, frame) {
      const sprite = spriteSets[name][frame % spriteSets[name].length];
      nekoEl.style.backgroundPosition = `${sprite[0] * 32}px ${sprite[1] * 32}px`;
    }

    function resetIdleAnimation() {
      idleAnimation = null;
      idleAnimationFrame = 0;
      idleTime = 0;
    }

    function getDirectionFromVelocity(velX, velY) {
      const magnitude = Math.sqrt(velX * velX + velY * velY);
      if (magnitude < 0.1) return null;

      const normalizedX = velX / magnitude;
      const normalizedY = velY / magnitude;
      const angle = Math.atan2(normalizedY, normalizedX) * (180 / Math.PI);

      if (Math.abs(angle) <= angleThreshold || Math.abs(angle) >= 180 - angleThreshold) {
        return normalizedX > 0 ? "E" : "W";
      } else if (Math.abs(angle - 90) <= angleThreshold || Math.abs(angle + 90) <= angleThreshold) {
        return normalizedY > 0 ? "S" : "N";
      }
      
      let direction = "";
      direction += normalizedY < -0.5 ? "N" : "";
      direction += normalizedY > 0.5 ? "S" : "";
      direction += normalizedX > 0.5 ? "E" : "";
      direction += normalizedX < -0.5 ? "W" : "";
      return direction;
    }

    function handleWallCollision() {
      let hasCollided = false;
      
      if (nekoPosX < 16) {
        nekoPosX = 16;
        velocityX = Math.abs(velocityX) * bounceMultiplier;
        setSprite("scratchWallW", frameCount);
        hasCollided = true;
      }
      if (nekoPosX > window.innerWidth - 16) {
        nekoPosX = window.innerWidth - 16;
        velocityX = -Math.abs(velocityX) * bounceMultiplier;
        setSprite("scratchWallE", frameCount);
        hasCollided = true;
      }
      if (nekoPosY < 16) {
        nekoPosY = 16;
        velocityY = Math.abs(velocityY) * bounceMultiplier;
        setSprite("scratchWallN", frameCount);
        hasCollided = true;
      }
      if (nekoPosY > window.innerHeight - 16) {
        nekoPosY = window.innerHeight - 16;
        velocityY = -Math.abs(velocityY) * bounceMultiplier;
        setSprite("scratchWallS", frameCount);
        hasCollided = true;
      }

      return hasCollided;
    }

    function idle() {
      idleTime += 1;

      if (forceSleep) {
        idleAnimation = "sleeping";
      } else if (idleTime > 10 && Math.floor(Math.random() * 200) === 0 && idleAnimation === null) {
        let availableIdleAnimations = ["sleeping", "scratchSelf"];
        if (nekoPosX < 32) availableIdleAnimations.push("scratchWallW");
        if (nekoPosY < 32) availableIdleAnimations.push("scratchWallN");
        if (nekoPosX > window.innerWidth - 32) availableIdleAnimations.push("scratchWallE");
        if (nekoPosY > window.innerHeight - 32) availableIdleAnimations.push("scratchWallS");
        
        idleAnimation = availableIdleAnimations[Math.floor(Math.random() * availableIdleAnimations.length)];
      }

      switch (idleAnimation) {
        case "sleeping":
          if (idleAnimationFrame < 8 && nudge) {
            setSprite("idle", 0);
            break;
          } else if (nudge && !forceSleep) {
            nudge = false;
            resetIdleAnimation();
          }
          if (idleAnimationFrame < 8) {
            setSprite("tired", 0);
            break;
          }
          setSprite("sleeping", Math.floor(idleAnimationFrame / 4));
          if (idleAnimationFrame > 192 && !forceSleep) {
            resetIdleAnimation();
          }
          break;
        case "scratchWallN":
        case "scratchWallS":
        case "scratchWallE":
        case "scratchWallW":
        case "scratchSelf":
          setSprite(idleAnimation, idleAnimationFrame);
          if (idleAnimationFrame > 9) {
            resetIdleAnimation();
          }
          break;
        default:
          setSprite("idle", 0);
          return;
      }
      idleAnimationFrame += 1;
    }

    function updatePhysics() {
      if (!isDragging && isBeingThrown) {
        nekoPosX += velocityX;
        nekoPosY += velocityY;

        const hasCollided = handleWallCollision();
        
        if (!hasCollided) {
            const direction = getDirectionFromVelocity(velocityX, velocityY);
            if (direction) {
                setSprite(direction, Math.floor(frameCount / ANIMATION_SPEED_DIVISOR));
            } else {
                setSprite("idle", 0);
            }
        }

        velocityX *= friction;
        velocityY *= friction;

        if (Math.abs(velocityX) < 0.3 && Math.abs(velocityY) < 0.3) {
          isBeingThrown = false;
          velocityX = 0;
          velocityY = 0;
        }

        nekoEl.style.left = `${nekoPosX - 16}px`;
        nekoEl.style.top = `${nekoPosY - 16}px`;
      }
    }

    function frame() {
        const currentTime = Date.now();
        const deltaTime = currentTime - lastFrameTime;
        
        if (deltaTime >= FRAME_INTERVAL) {
            frameCount += 1;
            lastFrameTime = currentTime;
        }
        if (isDragging || isBeingThrown) return;
  
        const diffX = nekoPosX - mousePosX;
        const diffY = nekoPosY - mousePosY;
        const distance = Math.sqrt(diffX ** 2 + diffY ** 2);
  
        if (forceSleep && distance < nekoSpeed) {
          nekoPosX = mousePosX;
          nekoPosY = mousePosY;
          nekoEl.style.left = `${nekoPosX - 16}px`;
          nekoEl.style.top = `${nekoPosY - 16}px`;
          idle();
          return;
        }
  
        if (distance < nekoSpeed || distance < 24) {
          idle();
          return;
        }
  
        idleAnimation = null;
        idleAnimationFrame = 0;
  
        if (idleTime > 1) {
          setSprite("alert", 0);
          idleTime = Math.min(idleTime, 7);
          idleTime -= 1;
          return;
        }
  
        const angle = Math.atan2(diffY, diffX) * (180 / Math.PI);
        let direction = "";
  
        if (Math.abs(angle) <= angleThreshold || Math.abs(angle) >= 180 - angleThreshold) {
          direction = diffX > 0 ? "W" : "E";
        } else if (Math.abs(angle - 90) <= angleThreshold || Math.abs(angle + 90) <= angleThreshold) {
          direction = diffY > 0 ? "N" : "S";
        } else {
          direction += diffY / distance > 0.5 ? "N" : "";
          direction += diffY / distance < -0.5 ? "S" : "";
          direction += diffX / distance > 0.5 ? "W" : "";
          direction += diffX / distance < -0.5 ? "E" : "";
        }
  
        // Slow down sprite animation by using floor(frameCount / 4) instead of floor(frameCount / 2)
        setSprite(direction, Math.floor(frameCount / ANIMATION_SPEED_DIVISOR));
  
        nekoPosX -= (diffX / distance) * nekoSpeed;
        nekoPosY -= (diffY / distance) * nekoSpeed;
  
        handleWallCollision();
        
        nekoEl.style.left = `${nekoPosX - 16}px`;
        nekoEl.style.top = `${nekoPosY - 16}px`;
      }

    function handleDragStart(e) {
      isDragging = true;
      let startX = e.clientX;
      let startY = e.clientY;
      let startNekoX = nekoPosX;
      let startNekoY = nekoPosY;
      let grabInterval;
      let lastFrameTime = Date.now();
      let animationFrameId;
      lastMouseX = e.clientX;
      lastMouseY = e.clientY;

      const updateDragAnimation = () => {
        const currentTime = Date.now();
        if (currentTime - lastFrameTime > FRAME_INTERVAL) {
          frameCount++;
          lastFrameTime = currentTime;
        }


        velocityX = mousePosX - lastMouseX;
        velocityY = mousePosY - lastMouseY;
        lastMouseX = mousePosX;
        lastMouseY = mousePosY;

        const deltaX = mousePosX - startX;
        const deltaY = mousePosY - startY;
        const absDeltaX = Math.abs(deltaX);
        const absDeltaY = Math.abs(deltaY);

        const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

        if (absDeltaX > 5 || absDeltaY > 5) {
            if (Math.abs(angle) <= angleThreshold || Math.abs(angle) >= 180 - angleThreshold) {
                setSprite(deltaX > 0 ? "scratchWallW" : "scratchWallE", 
                    Math.floor(frameCount / ANIMATION_SPEED_DIVISOR));
            } else if (Math.abs(angle - 90) <= angleThreshold || Math.abs(angle + 90) <= angleThreshold) {
                setSprite(deltaY > 0 ? "scratchWallN" : "scratchWallS", 
                    Math.floor(frameCount / ANIMATION_SPEED_DIVISOR));
            } else if (grabStop) {
                setSprite("alert", 0);
            }
        }

        nekoPosX = startNekoX + deltaX;
        nekoPosY = startNekoY + deltaY;

        handleWallCollision();

        nekoEl.style.left = `${nekoPosX - 16}px`;
        nekoEl.style.top = `${nekoPosY - 16}px`;

        if (isDragging) {
          animationFrameId = requestAnimationFrame(updateDragAnimation);
        }
      };

      const handleDragMove = (e) => {
        mousePosX = e.clientX;
        mousePosY = e.clientY;

        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;
        const absDeltaX = Math.abs(deltaX);
        const absDeltaY = Math.abs(deltaY);

        if (grabStop || absDeltaX > 5 || absDeltaY > 5 || Math.sqrt(deltaX ** 2 + deltaY ** 2) > 5) {
          grabStop = false;
          clearTimeout(grabInterval);
          grabInterval = setTimeout(() => {
            grabStop = true;
            nudge = false;
            startX = e.clientX;
            startY = e.clientY;
            startNekoX = nekoPosX;
            startNekoY = nekoPosY;
          }, 150);
        }
      };

      const handleDragEnd = () => {
        isDragging = false;
        nudge = true;
        resetIdleAnimation();
        cancelAnimationFrame(animationFrameId);

        const throwSpeed = Math.sqrt(velocityX * velocityX + velocityY * velocityY);
        if (throwSpeed > minimumThrowVelocity) {
          isBeingThrown = true;
          velocityX *= throwMultiplier;
          velocityY *= throwMultiplier;
        }

        window.removeEventListener("mousemove", handleDragMove);
        window.removeEventListener("mouseup", handleDragEnd);
      };

      animationFrameId = requestAnimationFrame(updateDragAnimation);
      window.addEventListener("mousemove", handleDragMove);
      window.addEventListener("mouseup", handleDragEnd);
    }

    function handleMouseDown(e) {
      if (e.button !== 0) return;
      
      const rect = nekoEl.getBoundingClientRect();
      const clickX = e.clientX;
      const clickY = e.clientY;
      
      if (clickX >= rect.left && clickX <= rect.right && 
          clickY >= rect.top && clickY <= rect.bottom) {
        handleDragStart(e);
      }
    }
    function handleMouseMove(e) {
        mousePosX = e.clientX;
        mousePosY = e.clientY;
      }
  
      function handleDoubleClick() {
        forceSleep = !forceSleep;
        nudge = false;
        if (!forceSleep) {
          resetIdleAnimation();
        }
      }
  
      // Initialize element
      nekoEl.id = "oneko";
      nekoEl.style.width = "32px";
      nekoEl.style.height = "32px";
      nekoEl.style.position = "fixed";
      nekoEl.style.cursor = "grab";
      nekoEl.style.imageRendering = "pixelated";
      nekoEl.style.left = `${nekoPosX - 16}px`;
      nekoEl.style.top = `${nekoPosY - 16}px`;
      nekoEl.style.zIndex = 999;
      nekoEl.style.backgroundImage = `url('https://raw.githubusercontent.com/kyrie25/spicetify-oneko/main/assets/oneko/oneko-${variant}.gif')`;
      nekoEl.style.filter = kuroNekoRef.current ? "invert(100%)" : "none";
  
      document.body.appendChild(nekoEl);
  
      // Event listeners
      document.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mousemove", handleMouseMove);
      nekoEl.addEventListener("dblclick", handleDoubleClick);
      nekoEl.addEventListener("contextmenu", (e) => {
        e.preventDefault();
        kuroNekoRef.current = !kuroNekoRef.current;
        nekoEl.style.filter = kuroNekoRef.current ? "invert(100%)" : "none";
      });
  
      // Animation loop
      const intervalId = setInterval(() => {
        updatePhysics();
        if (!isDragging && !isBeingThrown) {
          frame();
        }
      }, FRAME_INTERVAL); // Changed from 16 to FRAME_INTERVAL
  
      // Cleanup function
      return () => {
        document.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mousemove", handleMouseMove);
        nekoEl.removeEventListener("dblclick", handleDoubleClick);
        clearInterval(intervalId);
        if (nekoEl.isConnected) document.body.removeChild(nekoEl);
      };
    }, [variant]); // Only re-run effect if variant changes
  
    return null;
  };
  
  export default Oneko;