import React from 'react';

const SimpleContactForm = () => {
  const email = "haidenveno+web@gmail.com";
  
  return (
    <a 
      href={`mailto:${email}`}
      className="px-8 py-3 bg-blue-600 rounded text-white hover:bg-blue-700 transition-colors"
    >
      Contact me
    </a>
  );
};

export default SimpleContactForm