import React from 'react';
import { ScrollAnimation } from './ScrollAnimation';

function About() {
  const paragraphs = [
    <p className="text-center text-xl text-gray-300">
      My journey into <span className="bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">defensive security</span> began unexpectedly while running game servers for children. Facing real-world <span className="bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">DDoS attacks</span>, I had to quickly improvise and adapt to implement defensive solutions like <span className="bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">reverse-proxy systems</span> to keep the servers running. This single unexpected event sparked my curiosity and passion for cybersecurity.
    </p>,
    <p className="text-center text-xl text-gray-300">
      During my time at <span className="bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">Lakehead University</span>, I took a liking to front-end development and PHP. While full-time software development wasn't my end goal, these experiences and learning journies built a strong foundation for understanding the underlying architecture of these vulnerable systems.
    </p>,
    <p className="text-center text-xl text-gray-300">
      Currently, I'm attending <span className="bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">Seneca Polytechnic</span> for <span className="bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">cybersecurity</span> while expanding my skillset in IT and <span className="bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">defensive security measures</span>, looking for opportunities to grow and apply my knowledge.
    </p>
  ];

  return (
    <section className="py-10 bg-black relative overflow-hidden">
      {/* Gradient dot background */}
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `radial-gradient(#ffffff 1px, transparent 1px), radial-gradient(#ffffff 1px, transparent 1px)`,
          backgroundSize: '50px 50px',
          backgroundPosition: '0 0, 25px 25px',
          opacity: 0.10,
          maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 80%, rgba(0,0,0,0.8) 100%)',
          WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 80%, rgba(0,0,0,0.8) 100%)',
        }}
      ></div>
      {/* Header */}
      <ScrollAnimation>
        <h2 className="text-center text-2xl font-bold mb-6 text-gray-300 relative z-10">EXPLORE ABOUT ME</h2>
      </ScrollAnimation>
      {/* Story Section */}
      <ScrollAnimation>
        <div className="flex flex-col items-center mb-4 relative z-10">
          <div className="w-px h-12 bg-gradient-to-t from-blue-500 to-transparent"></div>
          <div className="w-8 h-8 rounded-full bg-gradient-to-br from-blue-500 to-white flex items-center justify-center text-blue-900 font-bold">
            <span>1</span>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <h3 className="text-center text-3xl font-bold mb-4 text-blue-500 relative z-10">
          About
        </h3>
      </ScrollAnimation>
      <div className="max-w-3xl mx-auto px-4 space-y-6 relative z-10">
        {paragraphs.map((paragraph, index) => (
          <ScrollAnimation
            key={index}
            className="transition-opacity duration-1000 ease-in-out"
          >
            {paragraph}
          </ScrollAnimation>
        ))}
      </div>
    </section>
  );
}

export default About;