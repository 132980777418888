import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
// Simple imports from the main packages
import { FaReact, FaPython, FaDatabase, FaJava, FaJs, FaPhp, FaLinux, FaWindows } from 'react-icons/fa';
import { SiSplunk, SiSnort } from 'react-icons/si';

function ProgrammingIcons() {
  const icons = [
    { Icon: FaReact, name: 'React' },
    { Icon: FaPython, name: 'Python' },
    { Icon: FaDatabase, name: 'SQL' },
    { Icon: FaJava, name: 'Java' },
    { Icon: FaJs, name: 'JavaScript' },
    { Icon: FaPhp, name: 'PHP' },
    { Icon: FaWindows, name: 'Windows' },
    { Icon: FaLinux, name: 'Linux'},
    { Icon: SiSplunk, name: 'Splunk'},
    { Icon: SiSnort, name: 'Snort'}
  ];

  const repeatedIcons = [...icons, ...icons, ...icons];

  return (
    <div className="relative w-full h-16 mt-8 overflow-hidden">
      <motion.div
        className="flex absolute"
        initial={{ x: '0%' }}
        animate={{ x: '-33.33%' }}
        transition={{
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 20,
            ease: "linear",
          },
        }}
      >
        {repeatedIcons.map((icon, index) => (
          <div key={index} className="flex flex-col items-center mx-8">
            <icon.Icon size={24} className="text-white" />
            <p className="text-xs mt-1 text-white">{icon.name}</p>
          </div>
        ))}
      </motion.div>
    </div>
  );
}

function Experience() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const experiences = [
    {
      title: 'Computer Science Student',
      date: '2020-2022',
      description: (
        <div className="space-y-2">
          <p>
            Developed skills in <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">database management</span> and <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">web development</span> through an assortment of projects. Enhanced knowledge of <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">SQL database concepts</span> using <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">complex queries</span>, <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">subqueries</span> and <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">database design</span> principles.
          </p>
          <p>
            Created secure web applications with <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">HTML</span>, <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">CSS</span>, <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">PHP</span>, and <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">SQL</span> while implementing fundamental <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">security concepts</span> such as <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">reCAPTCHA</span> and <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">SQL sanitization</span>.
          </p>
          <p>
            Built fundamental <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">back-end systems</span> in <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Java</span> to create a flight registry system with a no-fly list. Developed a snake game using <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Arduino</span> on an 8x8 LED Matrix demonstrating programming with <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">embedded systems</span>.
          </p>
        </div>
      ),
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M2.25 5.25a3 3 0 013-3h13.5a3 3 0 013 3V15a3 3 0 01-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 01-.53 1.28h-9a.75.75 0 01-.53-1.28l.621-.622a2.25 2.25 0 00.659-1.59V18h-3a3 3 0 01-3-3V5.25zm1.5 0v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Cybersecurity Student',
      date: '2023-Current',
      description: (
        <div className="space-y-2">
          <p>
            Practiced <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Windows Administration</span> including <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Active Directory</span> and policy creation. Developed expertise in <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Linux Administration</span> focusing on <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">security hardening</span>, <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">nftables firewalls</span> and <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">automation</span> via <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">ansible</span> and <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">containerization</span>. Gained a deep understanding of <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">computer architecture</span> through building <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">32-bit systems</span>, including <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">CPU</span> and <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">RAM</span> from NAND chips, while working with <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Assembly</span>.
          </p>
          <p>
            Designed and implemented secure <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">network infrastructures</span> using Cisco hardware including but not limited to VLANs, port security, anti-DHCP snooping, and <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">multi-area OSPF</span>. Configured <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">AWS site-to-site VPN</span> using GRE over IPsec.
          </p>
          <p>
            Conducted <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">penetration testing</span> using Metasploit and performed <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">malware analysis</span> utilizing tools such as PE Explorer and PEiD. Implemented <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">system hardening</span> through CIS controls and performed network analysis using <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Wireshark</span>, tcpdump, and a plethora of other forensic tools.
          </p>
        </div>
      ),
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z" clipRule="evenodd" />
        </svg>
      )
    }
    
  ];

  return (
    <section ref={ref} className="py-10 bg-black text-white relative overflow-hidden">
      <div 
        className="absolute inset-0"
        style={{
          backgroundImage: `radial-gradient(#ffffff 1px, transparent 1px), radial-gradient(#ffffff 1px, transparent 1px)`,
          backgroundSize: '50px 50px',
          backgroundPosition: '0 0, 25px 25px',
          opacity: 0.10,
          maskImage: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 100%)',
          WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 100%)',
        }}
      />
      <div className="container mx-auto px-4 relative z-10">
        <motion.div 
          className="flex flex-col items-center mb-8"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.5, staggerChildren: 0.2 } },
          }}
        >
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { opacity: 1, y: 0 },
            }}
            className="flex flex-col items-center"
          >
            <div className="w-px h-12 bg-gradient-to-t from-red-500 to-transparent" />
            <div className="w-8 h-8 rounded-full bg-gradient-to-br from-red-500 to-orange-500 flex items-center justify-center font-bold text-white">
              <span>2</span>
            </div>
          </motion.div>
          <motion.h2
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { opacity: 1, y: 0 },
            }}
            className="text-3xl font-bold text-red-500 mt-4"
          >
            My Experience
          </motion.h2>
          
          <ProgrammingIcons />
        </motion.div>
        <div className="relative">
          <div className="absolute left-1/2 transform -translate-x-1/2 border-l-2 border-gray-700 opacity-30 h-full" />
          {experiences.map((exp, index) => (
            <ExperienceItem key={index} experience={exp} index={index} isLast={index === experiences.length - 1} />
          ))}
        </div>
      </div>
    </section>
  );
}
function ExperienceItem({ experience, index, isLast }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const variants = {
    hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
    visible: { opacity: 1, x: 0 },
  };
  
  // Determine which side gets the content based on index
  const leftContent = index % 2 === 0 ? (
    <div className="w-5/12" /> // Empty div for spacing
  ) : (
    <div className="w-5/12">
      <div className="border border-gray-700 rounded-lg p-4">
        <h3 className="text-xl font-bold mb-2 text-left">{experience.title}</h3>
        <p className="text-gray-500 mb-2 text-left">{experience.date}</p>
        <div className="text-gray-400 text-left">{experience.description}</div>
      </div>
    </div>
  );

  const rightContent = index % 2 === 0 ? (
    <div className="w-5/12">
      <div className="border border-gray-700 rounded-lg p-4">
        <h3 className="text-xl font-bold mb-2 text-left">{experience.title}</h3>
        <p className="text-gray-500 mb-2 text-left">{experience.date}</p>
        <div className="text-gray-400 text-left">{experience.description}</div>
      </div>
    </div>
  ) : (
    <div className="w-5/12" /> // Empty div for spacing
  );

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: 0.5, delay: 0.2 }}
      className="mb-16 flex justify-between items-center relative"
    >
      {leftContent}
      <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center">
        <div className="w-px h-8 bg-gray-700 opacity-100" />
        <div className="bg-black p-2 rounded-full text-gray-400 z-10">
          {experience.icon}
        </div>
        {!isLast && <div className="w-px h-8 bg-gray-700 opacity-30" />}
      </div>
      {rightContent}
    </motion.div>
  );
}

export default Experience;

